import { get, put,Delete,post } from '../axios'

// 发票列表-列表
export const invoiceList = params => get(`/order/web/invoice/manage/list`,params);
// 发票列表-确认开票
export const invoiceOpen = params => put(`/order/web/invoice/manage/open`,params);
// 发票列表-导出
export const invoiceExport = params => get(`/order/web/invoice/manage/export`,params);



