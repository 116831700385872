<template>
  <div class="divBox">
    <div class="selCard mb14">
      <el-form
        size="small"
        inline
        :model="tableFrom"
        ref="searchForm"
        label-width="85px"
      >
        <el-form-item label="下单人：" prop="nickname">
          <el-input
            v-model="tableFrom.nickname"
            placeholder="请输入下单人"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input
            v-model="tableFrom.phone"
            placeholder="请输入手机号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="时间：">
          <el-date-picker
            class="input-with-select selWidth"
            v-model="tableFrom.time"
            value-format="yyyy/MM/dd"
            format="yyyy/MM/dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            type="daterange"
            placement="bottom-end"
            style="width: 280px"
            :picker-options="pickerOptions"
          />
        </el-form-item>

        <el-form-item label="开票状态：" prop="invoiceState">
          <el-select v-model="tableFrom.invoiceState" placeholder="请选择" clearable>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="search()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
          <el-button size="small" @click="openAll()">确认开票</el-button>
          <el-button size="small" @click="exportFun()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        @selection-change="handleSelectionChange"
      >
        <el-table-column key="2" type="selection" width="55"/>
        <el-table-column label="订单编号" prop="code" min-width="170" />
        <el-table-column label="下单人" prop="nickname" min-width="170" />
        <el-table-column label="手机号" prop="phone" min-width="170" />
        <el-table-column label="商户名称" prop="storeName" min-width="170" />
        <el-table-column
          label="开票金额(元)"
          prop="invoicePrice"
          min-width="170"
        />
        <el-table-column
          label="订单金额(元)"
          prop="orderAmount"
          min-width="170"
        />
        <el-table-column
          label="实付金额(元)"
          prop="paidAmount"
          min-width="170"
        />
        <el-table-column label="积分抵扣" prop="scoreAmount" min-width="170" />
        <el-table-column label="支付类型" prop="code" min-width="170">
          <template slot-scope="scope">
            {{ payTypeE[scope.row.payType] }}
          </template>
        </el-table-column>
        <el-table-column label="发票类型" prop="type" min-width="170">
          <template slot-scope="scope"> 电子发票 </template>
        </el-table-column>
        <el-table-column label="企业/个人" prop="code" min-width="170">
          <template slot-scope="scope">
            {{ scope.row.invoiceType == 1 ? "企业" : "企业/事业单位" }}
          </template>
        </el-table-column>

        <el-table-column label="发票抬头" prop="invoiceName" min-width="170" />
        <el-table-column label="税号" prop="invoiceDuty" min-width="170" />
        <el-table-column
          label="发票联系电话"
          prop="invoicePhone"
          min-width="170"
        />
        <el-table-column label="发票邮箱" prop="invoiceEmail" min-width="170" />

        <el-table-column label="开票状态" prop="code" min-width="170">
          <template slot-scope="scope">
            {{ scope.row.invoiceState == 2 ? "已申请" : "已开票" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="140" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="scope.row.invoiceState != 3"
              icon="el-icon-info"
              @click="open(scope.row.id)"
              >确认开票</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
import { invoiceList, invoiceOpen, invoiceExport } from "@/api/invoice/invoice";
import moment from "moment";
import timeOptions from "@/assets/utils/timeOptions";
import { exportExcel } from "@/assets/utils/exportExcel";
export default {
  name:'invoice',
  data() {
    return {
      payTypeE: {
        0: "未选择",
        1: "微信支付",
        2: "支付宝 ",
        3: "积分支付",
        5: "混合支付",
        9: "免支付 ",
      },
      options: [
        {
          value: "2",
          label: "待开票",
        },
        {
          value: "3",
          label: "已开票",
        },
      ],
      pickerOptions: timeOptions,
      tableData: {
        total: 0,
      },
      supplierList: [],
      storeList: [],
      listLoading: false,
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        time: [],
        invoiceState: "",
        nickname: "",
        phone: "",
      },
      multipleSelection: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //导出
    exportFun() {
      if (this.tableFrom.time && this.tableFrom.time.length > 0) {
        this.tableFrom.createTimeStart = moment(this.tableFrom.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.tableFrom.createTimeEnd =
          moment(this.tableFrom.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.tableFrom.createTimeStart = "";
        this.tableFrom.createTimeEnd = "";
      }
      exportExcel("/order/web/invoice/manage/export", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "发票列表",
      });
    },
    //多选得id
    handleSelectionChange(val) {
      console.log('123',val)
      this.multipleSelection = val
    },
    openAll() {
      if(this.multipleSelection.length==0){
        this.$message.error('请选择数据')
        return
      }
      this.$confirm("确认开票?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        invoiceOpen({ ids: this.multipleSelection.map((ele)=>ele.id)  })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("开票成功");
            }
          })
          .catch((res) => {
            this.listLoading = false;
          });
      });

    },
    open(id) {
      this.$confirm("确认开票?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        invoiceOpen({ ids: [id] })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("开票成功");
            }
          })
          .catch((res) => {
            this.listLoading = false;
          });
      });
    },
    search() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    /**重置 */
    searchReset() {
      this.tableFrom.pageNum = 1;
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.getList();
    },
    // 列表
    getList() {
      this.listLoading = true;
      if (this.tableFrom.time && this.tableFrom.time.length > 0) {
        this.tableFrom.createTimeStart = moment(this.tableFrom.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.tableFrom.createTimeEnd =
          moment(this.tableFrom.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.tableFrom.createTimeStart = "";
        this.tableFrom.createTimeEnd = "";
      }
      invoiceList({ ...this.tableFrom, time: "" })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
  },
};
</script>


